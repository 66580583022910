var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "scraper-list"
    }
  }, [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Connectors",
      "sub-title": "Manage third-party connectors and integrations.",
      "category": "Automation",
      "category-logo": "fa-conveyor-belt",
      "category-route": {
        name: _vm.$route.name
      }
    }
  }), _vm.loading ? _c('Spinner') : _c('TabView', {
    attrs: {
      "route-name": "connectors",
      "tabs": _vm.tabs,
      "exact": false
    }
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }